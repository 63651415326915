<template>
  <div class="relative bg-white py-16 sm:py-16">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-0 flex justify-center">
            <img class="w-5/12 mx-auto md:mt-24" src="images/header.png" alt="">
          </div>
        </div>
        <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          <!-- Content area -->
          <div class="pt-3 sm:pt-5 lg:pt-32">
            <div class="mt-6 text-gray-600 space-y-6 text-center">
              <p class="text-2xl font-thin">
                <span class="font-semibold">Group Alliances</span> and <span class="font-semibold">Purolator</span> are joining forces to give you access to volume pricing no matter who you are.
              </p>
              <p class="text-2xl font-thin">Whether you are an SME, a distributor or a producer, it is with pleasure that we welcome you to our group.</p>
              <div class="mt-12 pt-5 sm:mt-8 sm:flex sm:justify-center">
                <div class="rounded-md shadow">
                  <router-link to="/contact" class="w-auto flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10">
                    Open an account for free!
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'FeatureCalculator',
  data() {
    return {
      response: null,
      Errors : []
    }
  }
}
</script>