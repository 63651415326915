<template>
  <div class="relative bg-white overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div class="relative z-10 py-12 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <div class="pt-10 mx-auto max-w-7xl px-5 sm:pt-12 sm:px-6 md:pt-16 lg:pt-20 lg:px-8 xl:pt-28">
            <div class="sm:text-center lg:text-left md:pl-12 pl-2">
              <h1 class="text-4xl tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
              Ship your <span class="font-semibold">packages</span> at the <span class="block font-semibold xl:inline">best prices</span>
              </h1>
              <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Group Alliances offers you savings on your shipments across Canada with its Purolator service
                <!-- <img class="h-6 mt-6 inline" src="/images/Puro logo.png" alt="Tuple"> -->
              </p>
              <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div class="rounded-md shadow">
                  <router-link class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-red-600 hover:bg-red-700 md:py-4 md:text-lg md:px-10" to="/contact">Try it free</router-link>
                </div>
                <!-- <div class="mt-3 sm:mt-0 sm:ml-3">
                  <a href="#" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 md:py-4 md:text-lg md:px-10">
                    Calcul d'économies
                  </a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 md:pt-20">
        <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-6 flex justify-center">
          <img class="inset-0 h-5/6 w-5/6" src="/images/100 rabais.svg" alt="">
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: 'PromoHero100'
}
</script>