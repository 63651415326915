<template>
    <footer class="relative bg-white py-16 sm:py-12 text-gray-600 my-8">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-12 lg:items-center">
        <div class="relative sm:py-5 lg:py-0 flex justify-center col-span-2">
          <img class="w-4/6" src="/images/Logo GA Ang final.svg" alt="">
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center md:text-left">
            <p class="font-semibold text-md my-3">1 (866) 880-6079</p>
            <p class="font-semibold text-md my-3">service@groupealliances.com</p>
            <p class="font-light text-md">3400 boul. Losch <br> St-Hubert QC J3Y 5T6</p>
          </div>
        </div>
      </div>
    </footer>
</template>

<script>
export default {
    name: 'PageFooter'
}
</script>