<template>
  <div class="relative bg-gray-100 py-16 sm:py-18 text-gray-600 mb-10">
      <div class="text-center my-12">
        <h3 class="text-3xl font-light">Ready to start <span class="font-semibold">saving</span> ?</h3>
      </div>
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-5 lg:gap-12 lg:items-start">
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon step 1.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Fill out the form, open a free account with Group Alliances. Get your account in just 24 hours, and start saving. </p>
          </div>
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon step 2.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Make your parcel shipments from the Purolator website using the access codes provided by Group Alliances</p>
          </div>
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon step 3.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Purolator takes care of everything. They pick up your packages at the door and deliver them to your destination on time. Express service available across Canada</p>
          </div>
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon facture.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Receive your invoice from Group Alliances detailing each of your shipments and save. You have the choice to pay by wire transfer, check or credit card</p>
          </div>
        </div>
        <div class="relative sm:py-5 lg:py-0">
          <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-2 text-center">
            <div class="flex justify-center pl-4">
              <img class="inset-0 w-4/6" src="/images/icon step 4.svg" alt="">
            </div>
            <p class="font-light text-md mt-6">Save time thanks to our service if you have any questions or notice an anomaly on your invoice, contact us. You will receive an immediate and personalized assistance</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'Process'
}
</script>