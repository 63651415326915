<template>
  <div class="divide-y divide-gray-200">
    <div class="mt-16">
        <div class="w-full">
            <h3 class="font-bold text-gray-600 text-left px-4">Your shipping quotes</h3>
            <div class="mt-5 w-full">
                <div v-for="(quote, index) in quotes" :key="quote.TotalPrice">
                  <Result :quote="quote" :index="index"></Result>
                </div>
            </div>
        </div>
    </div>
    <div class="pt-10 flex items-center space-x-4">
        <button class="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none" @click="back">
          <svg class="w-6 h-6 mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg> Back
        </button>
        <!-- <button @click="loadContact" class="bg-green-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none"><svg class="w-6 h-6 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 5l7 7-7 7M5 5l7 7-7 7" /></svg> Ouvrir un compte</button> -->
    </div>
  </div>
</template>

<script>
import Result from '../components/Result.vue'

export default {
  name: 'Results',
  components: {
    Result
  },
  props: {
    results: Object
  },
  data() {
    return {
      quotes: null
    }
  },
  mounted() {
    if(Array.isArray(this.results.ShipmentEstimate)) {
      this.quotes = this.results.ShipmentEstimate;
    } else {
      this.quotes = [this.results.ShipmentEstimate];
    }
  },
  methods: {
    back() {
      this.$emit('clear');
    },
    loadContact() {
      this.$emit('loadContact');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
