<template>
    <div>
        <a class="w-full border-t border-gray-100 font-medium text-gray-600 py-4 px-1 md:px-4 w-full block  transition duration-150" @click="expand()" :class="{ 'bg-gray-50': expanded}">
            <div class="h-6 w-6 bg-red-200 rounded-full flex inline-flex justify-center items-center text-red-400 font-mono"> {{index + 1}} </div>
            {{quote.ServiceID}}
            <br>
            <svg class="h-4 w-4 inline ml-7 mr-1 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path fill="#fff" d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z" /><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0" /></svg>
            <span class="text-gray-400 text-xs font-light sm:text-xs">Delivered on {{ niceDate(quote.ExpectedDeliveryDate) }}</span>
            <span class="text-red-400 font-bold xs:text-xs md:text-md float-right">${{roundAmount(quote.TotalPrice)}}</span>
            <div class="w-full min-h-20 px-6 py-4 mt-2 text-sm" v-if="this.expanded"> 
              <p class="font-bold mb-1">Details</p>
              <p>
                Base price : <span class="float-right">$ {{roundAmount(quote.BasePrice)}}</span>
              </p>
              <p v-for="charge in surcharges" :key="charge.Type">
                {{charge.Description}} <span class="font-light float-right">$ {{roundAmount(charge.Amount)}}</span>
              </p>
              <div v-for="tax in taxes" :key="tax.Type">
                  <p v-if="tax.Amount != 0">
                   {{tax.Description}} <span class="float-right">$ {{roundAmount(tax.Amount)}}</span>
                  </p>
              </div>
              <p class="font-bold border-t">Total <span class="float-right">$ {{roundAmount(quote.TotalPrice)}}</span></p>
            </div>
        </a>
    </div>
</template>

<script>
import moment from 'moment'
moment.locale("en-ca")

export default {
  name: 'Result',
  props: {
    quote: Object,
    index: Number,
  },
  data() {
    return {
      surcharges: [],
      taxes: [],
      expanded: false,
    }
  },
  mounted() {
    if(this.quote.ServiceID == 'PurolatorExpress') {
      this.expanded = true;
    } 

    if(Array.isArray(this.quote.Surcharges.Surcharge)) {
      this.surcharges = this.quote.Surcharges.Surcharge;
    } else {
      this.surcharges = [this.quote.Surcharges.Surcharge];
    }

    if(Array.isArray(this.quote.Taxes.Tax)) {
      this.taxes = this.quote.Taxes.Tax;
    } else {
      this.taxes = [this.quote.Taxes.Tax];
    }
  },
  methods: {
    expand() {
      this.expanded = !this.expanded;
    },
    niceDate(date) {
      return moment(date, "YYYY-MM-DD").format('MMMM Do YYYY');
    },
    roundAmount(amount){
      return parseFloat(amount).toFixed(2);
    }
  }
}
</script>